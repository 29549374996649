<template>
  <!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <!-- header-title -->
      <div class="header-column header-title">
        쳬육시설 개인 신청 내역
      </div>
      <!-- //header-title -->
      <!-- header-util -->
      <div class="header-column header-util left">
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-facility">

    <!-- main-content -->
    <div class="main-content main-component">

      <div class="">
        <div class="view-contents">

<!--
          <article class="content-section section-intro">
            <p class="text font-body14 text-muted">신청자 수정은 안내사항 동의서에서 수정하시고 동의서에 동의하시기 바랍니다.</p>
            <button class="kb-btn-content-item mt-3">안내사항 동의서</button>
          </article>
-->
          <!-- content-section: -->
          <article class="content-section">
            <!--
            <header class="section-header">
              <h3 class="title">예약정보</h3>
            </header>
            -->
            <div class="kb-form-fields kb-form-fields-v2 kb-form-fields-divider" style="border-top:none; padding-top:0px;">
              <!-- kb-form-field:이용일시 -->
              <div class="kb-form-field field-first">
                <div class="kb-form-row">
                  <label for="" class="kb-form-label"><span class="kb-form-label-text">이용일시</span></label>
                </div>
                <div class="kb-form-row">
                  <span class="kb-form-text">{{ fcltRsvtAplyIns.aplyYmd }} ・ {{ getSportTime(fcltRsvtAplyIns)}}</span>
                </div>
              </div>
              <!-- kb-form-field:신청자 1 -->
              <div class="kb-form-field">
                <div class="kb-form-row">
                  <label for="" class="kb-form-label"><span class="kb-form-label-text">신청자 1</span></label>
                </div>
                <div class="kb-form-row">
                  <span class="kb-form-text">{{ fcltRsvtAplyIns.aplcntDeptNm }} ・ {{ fcltRsvtAplyIns.aplcntNm }} | {{ fcltRsvtAplyIns.aplcntId }}</span>
                </div>
                <div class="kb-form-row">
                  <label for="" class="kb-form-label"><span class="kb-form-label-text">신청자 1 주민번호</span></label>
                </div>
                <div class="kb-form-row">
                  <input type="text" maxlength="6" class="kb-form-control" v-model="brdt" placeholder="주민번호 앞 6자리를 입력하세요." />
                </div>
              </div>
              <!-- kb-form-field:신청자 2 -->
              <div class="kb-form-field">
                <div class="kb-form-row">
                  <label for="" class="kb-form-label"><span class="kb-form-label-text">신청자 2</span></label>
                </div>
                <div class="kb-form-row">
                  <div class="kb-form-search">
                    <input @keyup.enter="searchUser" v-model="userSearchParam.searchText" type="text" class="kb-form-control" placeholder="직번을 입력하세요." />
                    <button class="kb-form-search-btn" @click="searchUser"><i class="icon-search"></i></button>
                  </div>
                </div>
                <div class="kb-form-row">
                  <span class="kb-form-text">{{ fcltRsvtAplyIns.aplcntDeptNm2 }} ・ {{ fcltRsvtAplyIns.aplcntNm2 }} | {{ fcltRsvtAplyIns.aplcntId2 }}</span>
                </div>
                <div class="kb-form-row">
                  <label for="" class="kb-form-label"><span class="kb-form-label-text">신청자 2 주민번호</span></label>
                </div>
                <div class="kb-form-row">
                  <input type="text" maxlength="6" class="kb-form-control" v-model="brdt2" placeholder="주민번호 앞 6자리를 입력하세요." />
                </div>
              </div>
              <!-- kb-form-field:이용인원 -->
              <div class="kb-form-field">
                <div class="kb-form-row">
                  <label for="" class="kb-form-label"><span class="kb-form-label-text">이용인원</span></label>
                </div>
                <div class="kb-form-row">
                  <input type="number" maxlength="3" class="kb-form-control" v-model="aplyPeopl" placeholder="이용인원을 입력하세요." />
                  <div class="kb-form-side">명</div>
                </div>
              </div>
              <!-- kb-form-field:사무실 연락처 -->
              <div class="kb-form-field">
                <div class="kb-form-row">
                  <label for="" class="kb-form-label"><span class="kb-form-label-text">신청자1 연락처</span></label>
                </div>
                <div class="kb-form-row">
                  <input type="tel" maxlength="12" class="kb-form-control" v-model="aplcntTelno" />
                </div>
              </div>
              <!-- kb-form-field:휴대폰 연락처 -->
              <div class="kb-form-field">
                <div class="kb-form-row">
                  <label for="" class="kb-form-label"><span class="kb-form-label-text">신청자2 연락처</span></label>
                </div>
                <div class="kb-form-row">
                  <input type="tel" maxlength="12" class="kb-form-control"  v-model="aplcntTelno2" />
                </div>
              </div>
              <!-- kb-form-field:부점/단체명 -->
              <div class="kb-form-field">
                <div class="kb-form-row">
                  <label for="" class="kb-form-label"><span class="kb-form-label-text">부점/단체명</span></label>
                </div>
                <div class="kb-form-row">
                  <div class="kb-form-dropdown" :class="{'is-active' : grpToggle}">
                    <button class="kb-form-dropdown-btn" @click="grpToggle=!grpToggle"><span class="text">{{ grpDivNm }}</span><i class="icon-arrow"></i></button>

                    <div class="kb-form-dropdown-options" style="position:absolute; left:0; width:100%;">
                      <div class="dropdown-option">
                        <ul class="dropdown-option-list">
                          <li class="dropdown-option-item" v-for="item in codeList" :key="item.cdSn">
                            <a @click="selectGrp(item)" href="javascript:" class="dropdown-option-link"><span class="dropdown-option-text">{{ item.cdNm }}</span></a>
                          </li>
                        </ul>
                      </div>
                    </div>

                  </div>
                  <input type="text" class="kb-form-control left-margin" v-model="grpNm" />
                </div>
                <div class="kb-form-row">
                  <span class="kb-form-text text-muted font-body14">허위일 경우 향후 6개월간 이용제한</span>
                </div>
              </div>
              <!-- //kb-form-field -->
            </div>
          </article>


        </div>
      </div>
      <div class="page-buttons"><button class="kb-btn kb-btn-primary" @click="setUpdateFcltRsvtAply">수정</button></div>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>
<script>
import {computed, onMounted, reactive, ref} from 'vue';
import {useStore} from "vuex";

import {useRouter} from "vue-router";
import {
  ACT_GET_USER_LIST,
  ACT_SET_FCLT_APLY_MOBILE_INFO,
  ACT_UPDATE_FCLT_RSVT_APLY
} from "@/store/modules/trnct/trnct";
import {useAlert} from "@/assets/js/modules/common/alert";
import {getItems, isSuccess, lengthCheck,isTelFormat,isHpTelFormat} from "@/assets/js/util";

export default {
  name: 'DailyMobileSportsApplyDetail',
  components: {

  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const {showMessage} = useAlert();

    const session = computed(() => store.state.auth.session);
    const fcltRsvtAplyIns = computed(() => store.state.trnct.mobileSportsApplyInfo);
    const codeList = computed(() =>
        store.state.code.codes.filter(code => code.upCd === '2095000'));

    codeList.value.unshift({cd: '', cdNm: '단체구분'});

    const reserveVenue = ref('체육시설');

    const brdt = ref(store.state.trnct.mobileSportsApplyInfo.brdt);
    const brdt2 = ref(store.state.trnct.mobileSportsApplyInfo.brdt2);
    const aplyPeopl = ref(store.state.trnct.mobileSportsApplyInfo.aplyPeopl);
    const aplcntTelno = ref(store.state.trnct.mobileSportsApplyInfo.aplcntTelno);
    const aplcntTelno2 = ref(store.state.trnct.mobileSportsApplyInfo.aplcntTelno2);
    const grpDivCd = ref(store.state.trnct.mobileSportsApplyInfo.grpDivCdDcd);
    const grpDivNm = ref(store.state.trnct.mobileSportsApplyInfo.grpDivNm);
    const grpToggle = ref(false);
    const grpNm = ref(store.state.trnct.mobileSportsApplyInfo.grpNm);

    onMounted(() => {

    });

    const selectGrp = (item) => {
      grpToggle.value = !grpToggle.value;
      grpDivCd.value= item.cd;
      grpDivNm.value= item.cdNm;
    };

    const setUpdateFcltRsvtAply = async () => {
      if(brdt.value == null  || brdt.value == ''){
        showMessage('신청자1 주민번호 앞 6자리를 입력해주세요');
        return;
      }

      if(brdt2.value == null  || brdt2.value == ''){
        showMessage('신청자2 주민번호 앞 6자리를 입력해주세요');
        return;
      }

      if(aplyPeopl.value == null  || aplyPeopl.value == '' || aplyPeopl.value == 0){
        showMessage('이용인원을 입력해주세요');
        return;
      }

      if(aplcntTelno.value == null  || aplcntTelno.value == ''){
        showMessage('신청자 1 연락처를 입력해주세요');
        return;
      }

      if(!isTelFormat(aplcntTelno.value) && !isHpTelFormat(aplcntTelno.value) ){
        showMessage('신청자 1 연락처가 잘못되었습니다.');
        return false;          
      }            
                

      if(aplcntTelno2.value == null  || aplcntTelno2.value == ''){
        showMessage('신청자 2 연락처를 입력해주세요');
        return;
      }

      if(!isTelFormat(aplcntTelno2.value) && !isHpTelFormat(aplcntTelno2.value) ){
        showMessage('신청자 2 연락처가 잘못되었습니다.');
        return false;          
      }            
          

      if (aplcntTelno.value ==aplcntTelno2.value) {
        showMessage('신청자 1, 신청자 2 연락처를 동일하게 입력할 수 없습니다.');
        return;
      }        


      if(grpDivCd.value == null  || grpDivCd.value == ''){
        showMessage('단체를 선택해주세요');
        return;
      }

      if(grpNm.value == null  || grpNm.value == ''){
        showMessage('단체명을 입력해주세요.');
        return;
      }

      store.commit(`trnct/${ACT_SET_FCLT_APLY_MOBILE_INFO}`,
          {
              brdt: brdt.value,
              brdt2: brdt2.value,
              aplyPeopl: aplyPeopl.value,
              grpDivCdDcd: grpDivCd.value,
              grpDivNm: grpDivNm.value,
              grpNm: grpNm.value,
              aplcntTelno: aplcntTelno.value,
              aplcntTelno2: aplcntTelno2.value,
          });

      await store.dispatch(`trnct/${ACT_UPDATE_FCLT_RSVT_APLY}`, store.state.trnct.mobileSportsApplyInfo).then(res => {
        if (isSuccess(res)) {
          showMessage('수정 되었습니다.');
          router.go(-1);
        }else{
          showMessage('<span class="text-danger">!!! 수정할 수 없습니다 !!!</span><br/>신청자2의 신청제한 이나 동일 동호회 이용제한 등을 확인 해 주세요', ()=>{
            router.go(-1);
          });

        }
      }).catch((e) => {console.error(e);})
    }

    const goBack = () => {
      router.go(-1);
    }

    const getSportTime = (item) => {
      if(item.part01RsvtYn == 'Y'){
        return "08:00 - 10:00 (1회차)"
      }else if(item.part02RsvtYn == 'Y'){
        return "10:00 - 12:00 (2회차)"
      }else if(item.part03RsvtYn == 'Y'){
        return "12:00 - 14:00 (3회차)"
      }else if(item.part04RsvtYn == 'Y'){
        return "14:00 - 16:00 (4회차)"
      }else if(item.part05RsvtYn == 'Y'){
        return "16:00 - 18:00 (5회차)"
      }
    }

    const userSearchParam = reactive( {
      searchText : store.state.trnct.mobileSportsApplyInfo.searchText,
      totalCount : 0
    });

    const userList = ref([]);
    const searchUser = () => {
      if(userSearchParam.searchText == null){
        showMessage('직번을 입력해주세요.');
        return;
      }

      if (userSearchParam.searchText == fcltRsvtAplyIns.value.aplcntId) {
        showMessage('신청자1과 다른 직번을 입력해주십시오.');
        return;
      }      

      console.log('유저 검색 버튼 ')
      store.dispatch(`trnct/${ACT_GET_USER_LIST}`, {
        lrnerId: userSearchParam.searchText,
        pageNo: 1,
        pageSize: 100
      }).then(res=>{
        if(lengthCheck(res)){
          userList.value = getItems(res);
          console.log(userList.value);

          const data = {
            aplcntId2: userList.value[0].lrnerId,
            aplcntNm2: userList.value[0].lrnerNm,
            aplcntDeptCd2: userList.value[0].deptCd,
            aplcntDeptNm2: userList.value[0].deptNm,
          }

          store.commit(`trnct/${ACT_SET_FCLT_APLY_MOBILE_INFO}`, data);
        }else{
          showMessage('해당 직번이 없습니다.');
          userList.value = [];
          userSearchParam.totalCount = 0;

          const data = {
            aplcntId2: null,
            aplcntNm2: null,
            aplcntDeptCd2: null,
            aplcntDeptNm2: null,
          }

          store.commit(`trnct/${ACT_SET_FCLT_APLY_MOBILE_INFO}`, data);
        }
      }).catch(e=>{
        console.error(e);
      });
    }




    return {
      reserveVenue,
      session,
      setUpdateFcltRsvtAply,
      brdt,
      brdt2,
      aplyPeopl,
      aplcntTelno,
      aplcntTelno2,
      fcltRsvtAplyIns,
      grpDivCd,
      grpDivNm,
      grpToggle,
      grpNm,
      codeList,
      selectGrp,
      goBack,
      getSportTime,
      searchUser,
      userSearchParam
    };
  },
};
</script>
